import { PROPERTY_LISTING_TYPE } from "@constants/enum/property.enum";

export interface FeedStatus {
  should_advertise: boolean;
  rightmove: FeedDetails;
  zoopla: FeedDetails;
  onthemarket: FeedDetails;
  allotherportals: FeedDetails;
}

export interface FeedDetails {
  feed_state: MARKETING_FEED_STATES;
  feed_url: string;
}

export const enum MARKETING_FEED_ACTIONS {
  UPDATE = "update",
  REMOVE = "remove"
}

export const enum MARKETING_FEED_PORTALS {
  RIGHTMOVE = "rightmove",
  ZOOPLA = "zoopla",
  ON_THE_MARKET = "onthemarket",
  ALL_OTHER_PORTALS = "allotherportals"
}

export const enum MARKETING_FEED_STATES {
  NOT_ADDED = "not_added",
  ADDED = "added",
  REMOVED = "removed",
  UNDER_OFFER = "under_offer",
  LET = "let",
  SOLD = "sold"
}

export interface UpdateActions {
  action: MARKETING_FEED_ACTIONS;
  feed_id: MARKETING_FEED_PORTALS;
}

export interface UpdateMarketingFeedPayload {
  property_id: string;
  feed_details: UpdateActions[];
  listing_type: PROPERTY_LISTING_TYPE;
  action: MARKETING_FEED_ACTIONS;
}
